"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_STORE_DESIGNS = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query getStoreDesigns {\n    getColorList {\n      data {\n        id\n        selected\n        themes {\n          colors\n        }\n        imgInfo {\n          used\n          repeat\n          size\n          image {\n            id\n            scaledSrc {\n              w1920\n            }\n          }\n        }\n      }\n    }\n    viewer {\n      id\n      store {\n        id\n        storeDesigns {\n          id\n          data\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var GET_STORE_DESIGNS = (0, _client.gql)(_templateObject());
exports.GET_STORE_DESIGNS = GET_STORE_DESIGNS;